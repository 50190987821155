import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";
import * as Lottie from "lottie-web/build/player/lottie_light";

declare const $: any;

export class LottieAnimation extends AbstractComponent {

    public static selector: string = 'lottie-animation';
    public animationPlayed: boolean = false;

    public init()
    {
        let container = $('#' + this.getComponentElement().attr("container-key"));
        let lottie: any = Lottie;
        let windowHeight = $(window).height();
        let loop = this.getComponentElement().attr("animation-loop") === "true";
        let delay = parseInt(this.getComponentElement().attr("animation-delay"));
        let autoplay = this.getComponentElement().attr("animation-auto-play") === "true" && delay===0;
        let loopDelay = parseInt(this.getComponentElement().attr('animation-loop-delay'));
        let placeholderFrame = parseInt(this.getComponentElement().attr('animation-placeholder-frame'));

        let animation = lottie.loadAnimation({
            container: container[0],
            renderer: 'svg',
            loop: false,
            autoplay: autoplay,
            animationData: JSON.parse(this.getComponentElement().attr('animation-data')),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
            }
        });

        if(this.getComponentElement()[0].hasAttribute('ie-fix')){
            this._ieFix();
        }

        if(this.getComponentElement().attr('animation-placeholder-frame'))
        {
            animation.goToAndStop(placeholderFrame);
        }

        $(window).on('load', () => {

            if (loop)
            {
                animation.addEventListener('complete', () => {
                    setTimeout(() => {
                        animation.goToAndPlay(1);
                    }, loopDelay)
                });
            }

            let containerHeight = this.getComponentElement().height();

            if (this.getComponentElement().attr("animation-auto-play") == 'false') {

                if (delay)
                {
                    setTimeout(() => {
                        this.triggerAnimation(container, windowHeight, containerHeight, animation);
                    }, delay)
                }
                else
                {
                    this.triggerAnimation(container, windowHeight, containerHeight, animation);
                }

                // on scroll if is in view
                $(window).on('scroll', this.debounce(() => {

                    if (delay)
                    {
                        setTimeout(() => {
                            this.triggerAnimation(container, windowHeight, containerHeight, animation);
                        }, delay)
                    }
                    else
                    {
                        this.triggerAnimation(container, windowHeight, containerHeight, animation);
                    }

                }, 50));
            }
        });

        this.getComponentElement().on('triggerAnimation', () => {
            animation.goToAndPlay(0);
        });
    }

    private triggerAnimation(container, windowHeight, containerHeight,  animation)
    {
        if(!this.animationPlayed)
        {
            let windowScrollPosition = $(window).scrollTop();
            let containerPositionFromTop = container.offset().top;
            if (
                containerPositionFromTop >= windowScrollPosition &&
                (containerPositionFromTop + containerHeight) <= (windowScrollPosition + windowHeight)
            ) {
                animation.goToAndPlay(1);
                this.animationPlayed = true;
            }
        }
    }

    private _ieFix(){
        $('body').css('overflow-x', 'hidden');
        let el = this.getComponentElement();
        let containerHeight = el.height();
        let containerWidth = el.width();
        let svg = el.find('svg');
        let svgHeight = svg.attr('height');
        let svgWidth = svg.attr('width');
        let ratio = containerHeight / svgHeight;

        //Scale Width
        if(el[0].currentStyle.height !== 'auto'){
            svg.css('height', containerHeight);
            svg.css('width', svgWidth * ratio);
        }

        //Scale Height (Default)
        else{
            ratio = containerWidth / svgWidth;
            svg.css('height', svgHeight * ratio);
            svg.css('width', containerWidth);
        }
    }
}

new ComponentLoader(LottieAnimation);
